<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="(item, key) in items"
      :key="key"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  data(){
    return{
      items:[]
    }
  },
  created(){
    let getUserData = useJwt.getUserRole();

    if( getUserData !== null && getUserData['role'] === 'publisher' ){
        this.items = [
          {
            title: 'Dashboard',
            route: 'publisher',
            icon: 'HomeIcon',
          },
          {
            title: 'Realtime',
            route: 'publisher-realtime',
            icon: 'TrendingUpIcon',
          },
          {
            title: 'Forecast',
            route: 'publisher-forecast',
            icon: 'RadioIcon',
          },
          {
            title: 'Inventory',
            icon: 'FileTextIcon',
            children: [
              {
                title: 'Websites/Apps',
                route: 'inventory',
              },
              {
                title: 'Inventory Slots',
                route: { name: 'publisher-slots' },
              },
            ],
          },
          {
            title: 'Protections',
            route: 'publisher-protections',
            icon: 'ShieldOffIcon',
          },
          {
            title: 'Reports',
            route: 'publisher-reports',
            icon: 'BarChartIcon',
          },
          {
            title: 'Payments',
            icon: 'BriefcaseIcon',
            children: [
              {
                title: 'Payments',
                route: 'publisher-payments',
                icon: 'ShoppingBagIcon',
              },
              {
                title: 'Payment Method',
                route: 'publisher-payment-method',
                icon:'SlackIcon'
              },
            ],
          }    
        ]    
    }

    if( getUserData !== null && getUserData['role'] === 'advertiser' ){
        this.items = [
          {
            title: 'Dashboard',
            route: 'advertiser',
            icon: 'HomeIcon',
          },
          {
            title: 'Manage Campaigns',
            icon: 'ZapIcon',
            children: [
              {
                title: 'Campaigns',
                route: 'advertiser-campaigns',
                icon:'CopyIcon'
              },
              {
                title: 'Ad Groups',
                route: 'advertiser-ad-groups',
                icon:'FramerIcon'
              },
              {
                title: 'Banners',
                route: 'advertiser-banners',
                icon:'ImageIcon'
              },
            ],
          },
        ]    
    }

    if( getUserData !== null && getUserData['role'] === 'admin' ){
        this.items = [
          {
            title: 'Dashboard',
            route: 'admin',
            icon: 'HomeIcon',
          },
          {
            header: 'System',
          },
          {
            title: 'Overview',
            route: 'admin-overview',
            icon: 'ActivityIcon',
          },
          {
            title: 'Realtime',
            route: 'admin-realtime',
            icon: 'ClockIcon',
          },
          {
            title: 'Currency',
            route: 'admin-currency',
            icon: 'DollarSignIcon',
          },
          {
            title: 'Currency',
            route: 'admin-currency',
            icon: 'DollarSignIcon',
          },
          {
            header: 'Inventory',
          },
          {
            title: 'Websites/Apps',
            route: 'admin-inventory',
            icon: 'LayoutIcon',
          },
          {
            title: 'Slots',
            route: 'admin-slots',
            icon: 'GridIcon',
          },
          {
            title: 'Scout',
            route: 'admin-scout',
            icon: 'ScissorsIcon',
          },
          {
            header: 'CAMPAIGNS',
          },
          {
            title: 'Campaigns',
            route: 'admin-campaigns',
            icon: 'SendIcon',
          },
          {
            title: 'Ad Groups',
            route: 'admin-ad-groups',
            icon: 'LayersIcon',
          },
          {
            title: 'Banners',
            route: 'admin-banners',
            icon: 'ImageIcon',
          },
          {
            header: 'USERS',
          },
          {
            title: 'Users',
            icon: 'UsersIcon',
            children: [
              {
                title: 'All',
                route: 'all-users',
              },
              {
                title: 'Administrator',
                route: 'user-administrator',
              },
              {
                title: 'Publisher',
                route: 'user-publisher'
              },
              {
                title: 'Advertiser',
                route: 'user-advertiser'
              },
              {
                title: 'Agencies',
                route: 'user-agencies'
              },
            ],
          },
          {
            title: 'Users ACL',
            icon: 'UsersIcon',
            children: [
              {
                title: 'User Groups',
                route: 'user-groups',
              },
              {
                title: 'User Groups Permission',
                route: 'user-group-permissions',
              }
            ],
          },
          {
            title: 'Analytics',
            route: 'dashboard-analytics',
            icon: 'BarChartIcon',
          },
        ]    
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
