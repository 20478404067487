<template>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <span class="ml-50 text-body" ref="reportrange"> Last 7 Days </span>
        <feather-icon
          icon="ChevronDownIcon"
          size="21"
        />
      </template>
      <vue-perfect-scrollbar
        v-once
        class="media-list"
        tagname="li"
      >
        <b-link
          v-for="datarange in dataranges"
          :key="datarange.id"
          @click="selectedDate(datarange)"
        >
          <b-media>
            <p class="media-heading">
              <span class="font-weight-normal">
                {{ datarange.title }}
              </span>
            </p>
            <!-- <p class="">
              <flat-pickr
                  v-model="today"
                  ref="rangedate"
                  :config="{ mode: 'range', enableTime: true, dateFormat: 'Y-m-d H:i:s'}"
              />
            </p> -->
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>
  </template>
  
  <script>
  import {
    BNavItemDropdown, BMedia, BLink
  } from 'bootstrap-vue'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  // import flatPickr from 'vue-flatpickr-component'
  
  export default {
    components: {
      BNavItemDropdown,
      BMedia,
      BLink,
      VuePerfectScrollbar,
      // flatPickr
    },
    data(){
      return {
        dataranges : [{id:1,title:'Today'},{id:2,title:'Yesterday'},{id:3,title:'Last 7 Days'},{id:4,title:'Last 30 Days'},{id:5,title:'This Month'},{id:6,title:'Last Month'},{id:7,title:'Lifetime'},{id:8,title:'Custom Range'}]
      }
    },
    methods:{
      selectedDate(e){
        if(e.title.trim() != this.$refs.reportrange.innerText.trim()){
          this.$router.push({
            query: {
              type: e.id,
              range: encodeURI(e.title)
            }
          });
        }
        this.$refs.reportrange.innerText = e.title + ' ';
      },
    }
  }
  </script>
  
  <style>
    .dropdown-menu-media{width:max-content !important}
  </style>
  